<template>
  <div class="wrapper-main__content">
    <div class="form-content">
      <div class="d-flex flex-wrap py-2 border-bottom">
        <VocabularyTitle :title="$t('sideBar.coPlacementProhibitions')" :per="paginationData.per_page" :entries="paginationData.total" />
        <PaginateWrapper v-if="paginationData" :last-page="paginationData.last_page" :current="filterPage" class="ml-auto" @pageClick="pageClick" />

        <!--Search-->
        <div class="d-flex justify-content-between gap-3 mt-3 w-100">
          <div class="d-flex gap-3">
            <search-dropdown v-model="selectedPerPage" @onSelect="pageClick(1)" />

            <MultiSelect
              v-model="filters.brand_group_1"
              label="name"
              track-by="id"
              :options="filterGroupList1"
              :placeholder="$t('table.selectGroup') + ' 1'"
              class="w-270px"
              @input="pageClick(1)"
            ></MultiSelect>
            <MultiSelect
              v-model="filters.brand_group_2"
              label="name"
              track-by="id"
              :options="filterGroupList2"
              :placeholder="$t('table.selectGroup') + ' 2'"
              class="w-270px"
              @input="pageClick(1)"
            ></MultiSelect>
          </div>

          <search-input v-model="searchElements" @searchTable="pageClick(1)" />
        </div>
        <!--Search-->
      </div>

      <!--TABLE-->
      <div class="table-responsive vocabulary-table">
        <table class="table table-hover" style="white-space: nowrap">
          <thead>
            <th scope="col">
              <sort-arrows :sort-string="sortString" :column="'name,'" @click="sortTable('name,', $event)" />
              {{ $t('table.name') }}
            </th>
            <th scope="col">
              <sort-arrows :sort-string="sortString" :column="'date_from,'" @click="sortTable('date_from,', $event)" />
              {{ $t('table.period') }}
            </th>
            <th scope="col">
              <sort-arrows :sort-string="sortString" :column="'brand_group_1_id,'" @click="sortTable('brand_group_1_id,', $event)" />
              {{ $t('table.group') }} 1
            </th>
            <th scope="col">
              <sort-arrows :sort-string="sortString" :column="'brand_group_2_id,'" @click="sortTable('brand_group_2_id,', $event)" />
              {{ $t('table.group') }} 2
            </th>
          </thead>
          <tbody class="overflow-hidden text-overflow-ellipsis">
            <tr v-if="coPlacementProhibitionsStatus !== 'success'">
              <td colspan="4"><SpinnerLoader :loading="coPlacementProhibitionsStatus" /></td>
            </tr>
            <tr v-for="(row, index) in tableData" :key="index">
              <td>
                <button
                  v-if="canView"
                  class="btn-icon"
                  :title="canEdit ? $t('table.edit') : $t('table.details')"
                  @click="showModalCoPlacementProhibitions('edit', row.id)"
                >
                  <b-icon icon="pencil"></b-icon>
                </button>
                <button v-if="canDelete" class="btn-icon mr-4" :title="$t('table.delete')" @click="showModalDeleteCoPlacementProhibitions(row)">
                  <b-icon icon="trash"></b-icon>
                </button>
                {{ row.name }}
              </td>
              <td>{{ row.date_from | convertDate }} &ndash; {{ row.date_to | convertDate }}</td>
              <td>
                {{ row.brand_group_1 ? row.brand_group_1.name : '' }}
              </td>
              <td>
                {{ row.brand_group_2 ? row.brand_group_2.name : '' }}
              </td>
            </tr>
            <tr v-if="coPlacementProhibitionsStatus === 'success' && tableData && tableData.length === 0">
              <td colspan="4">
                <div class="d-flex w-100 justify-content-center">
                  {{ $t('table.noContent') }}
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <!--TABLE-->

      <!--FOOTER-->
      <div class="input-pagin">
        <div class="d-flex justify-content-between">
          <b-button v-if="$checkPermissions('co_placement_prohibition.create')" variant="primary" @click="showModalCoPlacementProhibitions('add')">
            {{ $t('table.addProhibition') }}
          </b-button>
        </div>
      </div>
      <!--FOOTER-->

      <!--MODAL ADD/EDIT-->
      <b-modal
        ref="coPlacementProhibitions"
        size="lg"
        :title="getTitle()"
        :ok-disabled="$v.$invalid"
        :ok-title="modalName === 'add' ? $t('table.add') : $t('table.edit')"
        :cancel-title="$t('table.cancel')"
        :hide-footer="!canEdit"
        :busy="isModalBusy"
        @ok.prevent="showModalCoPlacementProhibitionsConfirm"
      >
        <SpinnerLoader v-if="isModalLoading" :loading="isModalLoading ? 'loading' : 'success'" />
        <form v-else @submit.stop.prevent="checkIfValidEditAddThenEnter">
          <input type="submit" value="Submit" class="hidden-submit" />
          <b-form-group :label="$t('table.writeTitle')" label-for="input-1-copl">
            <b-form-input
              id="input-1-copl"
              v-model.trim="modal.name"
              type="text"
              :placeholder="$t('table.enterTitle')"
              required
              autofocus
              :disabled="!canEdit"
            ></b-form-input>
          </b-form-group>

          <div class="d-flex w-100 justify-content-between">
            <b-form-group :label="$t('table.selectChannels')" style="width: 79%">
              <MultiSelect
                v-model="modal.channels"
                :options="channels ? channels.data : []"
                :multiple="true"
                :close-on-select="false"
                :placeholder="$t('table.selectChannels')"
                label="name"
                track-by="id"
                :disabled="modal.isAllChannels || !canEdit"
              ></MultiSelect>
            </b-form-group>
            <b-form-checkbox v-model="modal.isAllChannels" :disabled="!canEdit" name="check-button-channels" class="mr-2" style="margin-top: 2.3rem">
              {{ $t('table.selectAll') }}
            </b-form-checkbox>
          </div>

          <div class="d-flex w-100 justify-content-between">
            <b-form-group :label="$t('table.selectClass') + ' 1'" style="width: 49%">
              <MultiSelect
                v-model="modal.brand_class_1"
                :options="classes.data"
                :placeholder="$t('table.selectClass') + ' 1'"
                label="name"
                track-by="id"
                :disabled="!canEdit"
              ></MultiSelect>
            </b-form-group>
            <b-form-group :label="$t('table.selectGroups') + ' 1'" style="width: 49%">
              <MultiSelect
                v-model="modal.brand_group_1"
                :options="modal.group_list_1 || []"
                :placeholder="$t('table.selectGroups') + ' 1'"
                label="name"
                track-by="id"
                :disabled="!canEdit || !modal.brand_class_1"
              ></MultiSelect>
            </b-form-group>
          </div>

          <div class="d-flex w-100 justify-content-between">
            <b-form-group :label="$t('table.selectClass') + ' 2'" style="width: 49%">
              <MultiSelect
                v-model="modal.brand_class_2"
                :options="classes.data"
                :placeholder="$t('table.selectClass') + ' 2'"
                label="name"
                track-by="id"
                :disabled="!canEdit"
              ></MultiSelect>
            </b-form-group>
            <b-form-group :label="$t('table.selectGroups') + ' 2'" style="width: 49%">
              <MultiSelect
                v-model="modal.brand_group_2"
                :options="modal.group_list_2 || []"
                :placeholder="$t('table.selectGroups') + ' 2'"
                label="name"
                track-by="id"
                :disabled="!canEdit || !modal.brand_class_2"
              ></MultiSelect>
            </b-form-group>
          </div>

          <div class="d-flex w-100 justify-content-between">
            <b-form-group style="width: 49%" :label="$t('table.writeDateFrom')" label-for="datepicker-buttons-from">
              <datepicker-wrapper id="datepicker-buttons-from" v-model="modal.date_from" required :disabled="!canEdit" />
            </b-form-group>
            <b-form-group style="width: 49%" :label="$t('table.writeDateTo')" label-for="datepicker-buttons-to">
              <datepicker-wrapper id="datepicker-buttons-to" v-model="modal.date_to" required :min="modal.date_from" :disabled="!canEdit" />
            </b-form-group>
          </div>
        </form>
      </b-modal>

      <!-- CONFIRM -->
      <b-modal
        ref="coPlacementProhibitions-confirm"
        size="sm"
        :title="modalName === 'add' ? $t('table.addNewCoPlacementProhibitions') : $t('table.editCoPlacementProhibitions')"
        :ok-title="$t('table.yes')"
        :cancel-title="$t('table.no')"
        auto-focus-button="ok"
        :busy="isModalBusy"
        @ok.prevent="addCoPlacementProhibitions"
      >
        <p class="my-2">
          {{ modalName === 'add' ? $t('table.confirmAddCoPlacementProhibitions') : $t('table.confirmEditCoPlacementProhibitions') }}
          <span class="text-danger">{{ modal.name }}</span>
          ?
        </p>
      </b-modal>
      <!--MODAL ADD/EDIT-->

      <!--MODAL DELETE-->
      <b-modal
        ref="delete-coPlacementProhibitions-confirm"
        size="sm"
        :title="$t('table.deleteCoPlacementProhibitions')"
        :ok-title="$t('table.yes')"
        :cancel-title="$t('table.no')"
        auto-focus-button="ok"
        :busy="isModalBusy"
        @ok.prevent="deleteCoPlacementProhibitions"
      >
        <p class="my-2">
          {{ $t('table.confirmDeleteCoPlacementProhibitions') }}
          <span class="text-danger">{{ rowId.name }}</span>
          ?
        </p>
      </b-modal>
      <!--MODAL DELETE-->
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { required, sameAs, not } from 'vuelidate/lib/validators';
import MultiSelect from '@/components/MultiSelect';
import VocabularyTitle from '@/components/VocabularyTitle';
import SpinnerLoader from '@/components/SpinnerLoader';
import errorsHandler from '@/utils/errorsHandler';
import PaginateWrapper from '../components/PaginateWrapper';
import SearchDropdown from '../components/SearchDropdown';
import SearchInput from '../components/SearchInput';
import convertDate from '@/filters/convertDate';
import sortTableMixin from '@/mixins/sortTable.js';
import adminPanelQuery from '@/mixins/adminPanelQuery.js';
import SortArrows from '../components/SortArrows';
import DatepickerWrapper from '@/components/DatepickerWrapper.vue';

export default {
  name: 'CoPlacementProhibitions',
  components: { PaginateWrapper, SpinnerLoader, VocabularyTitle, MultiSelect, SearchDropdown, SearchInput, SortArrows, DatepickerWrapper },
  filters: {
    convertDate,
  },
  mixins: [sortTableMixin, adminPanelQuery],
  props: {
    q: { type: String, default: undefined },
    per_page: { type: [String, Number], default: undefined },
    on_page: { type: [String, Number], default: undefined },
    sort_mode: { type: String, default: undefined },
    brand_group_id_1: { type: [String, Number], default: undefined },
    brand_group_id_2: { type: [String, Number], default: undefined },
  },

  data() {
    return {
      modal: {
        brand_class_1: '',
        brand_class_2: '',
        brand_group_1: '',
        brand_group_2: '',
        group_list_1: [],
        group_list_2: [],
        name: '',
        date_from: '',
        date_to: '',
        channels: [],
        isAllChannels: false,
      },
      rowId: '',
      tableData: [],
      paginationData: '',
      searchElements: '',
      filterPage: 1,
      selectedPerPage: '100',
      modalName: '',
      filters: {
        brand_group_1: '',
        brand_group_2: '',
      },
      filterGroupList1: [],
      filterGroupList2: [],
      isModalBusy: false,
      isModalLoading: true,
    };
  },
  computed: {
    ...mapGetters({
      isThemeHeader: 'isTheme',
      coPlacementProhibitions: 'getCoPlacementProhibitions',
      coPlacementProhibitionsStatus: 'getCoPlacementProhibitionsStatus',
      modalEditCoPlacementProhibitions: 'getModalEditCoPlacementProhibitions',
      channels: 'getChannels',
      classes: 'getClasses',
      isLocale: 'isLocale',
      group: 'getGroup',
      modalEditClasses: 'getModalEditClasses',
    }),
    canView: function () {
      return this.$checkPermissions('co_placement_prohibition.read');
    },
    canEdit: function () {
      return this.$checkPermissions('co_placement_prohibition.update');
    },
    canDelete: function () {
      return this.$checkPermissions('co_placement_prohibition.delete');
    },
  },
  validations() {
    return {
      modal: {
        name: { required },
        brand_class_1: { required },
        brand_class_2: { required },
        brand_group_1: { required, notSameBrand: not(sameAs('brand_group_2')) },
        brand_group_2: { required, notSameBrand: not(sameAs('brand_group_1')) },
        date_from: { required },
        date_to: { required },
      },
    };
  },
  watch: {
    coPlacementProhibitions() {
      this.setTableData();
    },
    async 'modal.brand_class_1'() {
      if (this.modal.brand_class_1) {
        await this.$store.dispatch('GET_CLASSES_ID', this.modal.brand_class_1.id);
        this.modal.group_list_1 = this.modalEditClasses.brand_groups;
        //clear group select if ID of its parent class differs
        if (this.modal.brand_group_1 && this.modal.brand_group_1.brand_class_id !== this.modal.brand_class_1.id) {
          this.modal.brand_group_1 = '';
        }
      } else {
        this.modal.brand_group_1 = '';
        this.modal.group_list_1 = [];
      }
    },
    async 'modal.brand_class_2'() {
      if (this.modal.brand_class_2) {
        await this.$store.dispatch('GET_CLASSES_ID', this.modal.brand_class_2.id);
        this.modal.group_list_2 = this.modalEditClasses.brand_groups;
        if (this.modal.brand_group_2 && this.modal.brand_group_2.brand_class_id !== this.modal.brand_class_2.id) {
          this.modal.brand_group_2 = '';
        }
      } else {
        this.modal.brand_group_2 = '';
        this.modal.group_list_2 = [];
      }
    },
  },
  created() {
    document.title = this.$i18n.t('sideBar.coPlacementProhibitions') + ' – OpenMediaLogic';
  },
  mounted() {
    this.getCoPlacementProhibitionInfo();
  },
  destroyed() {
    this.$store.commit('clearCoProhibitionsList');
  },
  methods: {
    async paramsData() {
      if (this.tableData) this.tableData.length = 0;
      await this.$store.dispatch('GET_CO_PLACEMENT_PROHIBITIONS', {
        page: +this.filterPage,
        per_page: +this.selectedPerPage,
        include: 'brandGroup1,brandGroup2,channels',
        'filter[name]': this.searchElements !== '' ? this.searchElements : null,
        'filter[brand_group_1_id]': this.filters.brand_group_1 ? this.filters.brand_group_1.id : null,
        'filter[brand_group_2_id]': this.filters.brand_group_2 ? this.filters.brand_group_2.id : null,
        sort: this.sortString,
      });
      this.updateQuery();
    },

    pageClick(page) {
      this.filterPage = page;
      this.paramsData();
    },

    // get table info
    setTableData() {
      if (this.coPlacementProhibitions) {
        this.tableData = this.coPlacementProhibitions.data;
        this.paginationData = this.coPlacementProhibitions.pagination;
        if (this.filterPage > this.coPlacementProhibitions.pagination.last_page) {
          this.filterPage = this.coPlacementProhibitions.pagination.last_page;
          this.pageClick(this.filterPage);
        }
      }
    },

    getTitle() {
      if (this.modalName === 'add') return this.$i18n.t('table.addNewCoPlacementProhibitions');
      else if (this.modalName === 'edit' && !this.canEdit) return this.modal.name;
      else return this.$i18n.t('table.editCoPlacementProhibitions');
    },

    async getCoPlacementProhibitionInfo() {
      await Promise.all([this.$store.dispatch('GET_GROUP', { per_page: 1000 }), this.$store.dispatch('GET_CHANNELS', { per_page: 1000 })]);
      this.filterGroupList1 = [{ name: this.$i18n.t('table.all'), id: null }, ...this.group.data];
      this.filterGroupList2 = [{ name: this.$i18n.t('table.all'), id: null }, ...this.group.data];
      await this.restoreFromQuery('coProhibitions');
      this.paramsData();
    },

    async getGenresList() {
      await this.$store.dispatch('GET_GENRES', { per_page: 1000 });
    },

    getChannelsName(prop) {
      let names = '';
      prop.forEach((channel, index) => {
        const ch = this.channels.data.find((el) => el.id === channel);
        if (prop.length === index + 1) {
          names += ch.name;
        } else {
          names += ch.name + ', ';
        }
      });
      return names;
    },

    clearData() {
      this.modal.group_list_1 = [];
      this.modal.group_list_2 = [];
      this.modal.brand_class_1 = '';
      this.modal.brand_class_2 = '';
      this.modal.brand_group_1 = '';
      this.modal.brand_group_2 = '';
      this.modal.name = '';
      this.modal.date_from = '';
      this.modal.date_to = '';
      this.modal.channels = [];
      this.modal.isAllChannels = false;
    },

    // modal add/edit
    async showModalCoPlacementProhibitions(name, data) {
      this.isModalLoading = true;
      const id = data;
      this.modalName = name;
      this.$refs['coPlacementProhibitions'].show();
      if (!(this.classes && this.classes.data.length > 0)) await this.$store.dispatch('GET_CLASSES', { per_page: 1000 });
      this.clearData();
      if (name === 'edit') {
        await this.$store.dispatch('GET_CO_PLACEMENT_PROHIBITIONS_ID', { id: id, data: { include: 'channels' } });
        if (this.modalEditCoPlacementProhibitions) {
          this.modal.name = this.modalEditCoPlacementProhibitions.name;
          this.modal.brand_group_1 = this.group.data.find((el) => el.id === this.modalEditCoPlacementProhibitions.brand_group_1_id);
          this.modal.brand_group_2 = this.group.data.find((el) => el.id === this.modalEditCoPlacementProhibitions.brand_group_2_id);
          this.modal.brand_class_1 = this.classes.data.find((el) => el.id === this.modal.brand_group_1.brand_class_id);
          this.modal.brand_class_2 = this.classes.data.find((el) => el.id === this.modal.brand_group_2.brand_class_id);
          this.modal.date_from = this.modalEditCoPlacementProhibitions.date_from;
          this.modal.date_to = this.modalEditCoPlacementProhibitions.date_to;

          //activate checkbox Select all channels if empty array (=all)
          if (this.modalEditCoPlacementProhibitions.channel_ids.length === 0) {
            this.modal.isAllChannels = true;
          } else {
            this.modalEditCoPlacementProhibitions.channel_ids.forEach((el) => {
              this.modal.channels.push(this.channels.data.find((ch) => ch.id === el));
            });
          }
        }
      }
      this.isModalLoading = false;
    },

    hideModalCoPlacementProhibitions() {
      this.$refs['coPlacementProhibitions'].hide();
    },
    showModalCoPlacementProhibitionsConfirm() {
      this.$refs['coPlacementProhibitions-confirm'].show();
    },
    hideModalCoPlacementProhibitionsConfirm() {
      this.$refs['coPlacementProhibitions-confirm'].hide();
    },
    async addCoPlacementProhibitions() {
      this.isModalBusy = true;
      let channelArray = [];
      if (!this.modal.isAllChannels && this.modal.channels.length !== 0) {
        this.modal.channels.forEach((el) => {
          channelArray.push(el.id);
        });
      } else {
        channelArray = null;
      }

      const formData = {
        brand_group_1_id: this.modal.brand_group_1.id,
        brand_group_2_id: this.modal.brand_group_2.id,
        name: this.modal.name,
        date_from: this.modal.date_from,
        date_to: this.modal.date_to,
        channels: channelArray,
        for_all_channels: this.isAllChannels ? true : false,
      };
      if (this.modalName === 'add') {
        await this.$store.dispatch('POST_CO_PLACEMENT_PROHIBITIONS', {
          formData,
          handler: () => {
            this.$notify({
              type: 'success',
              title: this.$i18n.t('alert.addCoPlacementProhibitions'),
              text: this.modal.name,
            });
            this.hideModalCoPlacementProhibitions();
            this.paramsData();
            this.clearData();
          },
          handlerError: (errors) => {
            errorsHandler(errors, this.$notify);
          },
        });
      } else {
        const id = this.modalEditCoPlacementProhibitions.id;
        await this.$store.dispatch('PUT_CO_PLACEMENT_PROHIBITIONS', {
          id,
          formData,
          handler: () => {
            this.$notify({
              type: 'success',
              title: this.$i18n.t('alert.editCoPlacementProhibitions'),
              text: this.modal.name,
            });
            this.hideModalCoPlacementProhibitions();
            this.paramsData();
            this.clearData();
          },
          handlerError: (errors) => {
            errorsHandler(errors, this.$notify);
          },
        });
      }
      this.hideModalCoPlacementProhibitionsConfirm();
      this.isModalBusy = false;
    },

    // modal delete
    async showModalDeleteCoPlacementProhibitions(data) {
      this.rowId = data;
      this.$refs['delete-coPlacementProhibitions-confirm'].show();
    },
    hideModalDeleteCoPlacementProhibitionsConfirm() {
      this.$refs['delete-coPlacementProhibitions-confirm'].hide();
    },
    async deleteCoPlacementProhibitions() {
      this.isModalBusy = true;
      const formData = this.rowId.id;
      await this.$store.dispatch('DELETE_CO_PLACEMENT_PROHIBITIONS_ID', {
        formData,
        handler: () => {
          this.$notify({
            type: 'success',
            title: this.$i18n.t('alert.deleteCoPlacementProhibitions'),
            text: this.rowId.name,
          });
          this.paramsData();
        },
        handlerError: (errors) => {
          errorsHandler(errors, this.$notify);
        },
      });
      this.hideModalDeleteCoPlacementProhibitionsConfirm();
      this.isModalBusy = false;
    },

    checkIfValidEditAddThenEnter() {
      if (!this.$v.$invalid && !this.isModalBusy) this.showModalCoPlacementProhibitionsConfirm();
    },
  },
};
</script>

<style lang="sass"></style>
